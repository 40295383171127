
import { defineComponent, computed } from 'vue'
// import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'Notice',
  props: ['showNotice'],
  setup(props, { emit }) {
    // const route = useRoute()
    // const state = reactive({
    //   id: route.params.id,
    // })
    const title = computed(() => {
      let title = ''
      switch (props.showNotice) {
        case 1:
          title = '网站使用须知'
          break
        case 2:
          title = '怡合达FA电商平台客户注册须知说明'
          break
        case 3:
          title = '隐私政策'
          break
      }
      return title
    })
    const closeNotice = () => {
      emit('update:showNotice', 0)
    }
    return {
      // ...toRefs(state),
      title,
      closeNotice,
    }
  },
})
